import { get } from 'lodash';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { setLocale, setQueryLocaleAction } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { initWixData } from '../../common/actions/init-wix-data';
import { getInstance } from '../../common/controller/helpers';
import { fetchInitialData } from '../actions/fetch-initial-data';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  fedopsAppName,
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  const isCustomUrlEnabled = get(appParams, 'baseUrls.customUrl') === 'true';
  await Promise.all([
    store.dispatch(initWixData(viewMode, ['categoryId', 'tagId'], config)),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber })),
    store.dispatch(setAppConfig({ bundleName, fedopsAppName })),
    isCustomUrlEnabled && store.dispatch(setCustomRoutes()),
  ]);

  await store.dispatch(fetchInitialData({ instanceId }));
}
