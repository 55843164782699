import { get } from 'lodash';
import { createAction } from '@wix/communities-blog-client-common';
import { getQueryLocale } from '../../selectors/locale-selectors';

export const FETCH_POST_STATS_BY_DATE_REQUEST = 'postStatsByDate/FETCH_REQUEST';
export const FETCH_POST_STATS_BY_DATE_SUCCESS = 'postStatsByDate/FETCH_SUCCESS';
export const FETCH_POST_STATS_BY_DATE_FAILURE = 'postStatsByDate/FETCH_FAILURE';

export const fetchPostStatsByDateRequest = createAction(FETCH_POST_STATS_BY_DATE_REQUEST);
export const fetchPostStatsByDateSuccess = createAction(FETCH_POST_STATS_BY_DATE_SUCCESS);
export const fetchPostStatsByDateFailure = createAction(FETCH_POST_STATS_BY_DATE_FAILURE);

export const fetchPostStatsByDate = (months) => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchPostStatsByDateRequest());
    const language = getQueryLocale(getState());
    const promise = request.post(`/v2/stats/publications/count`, {
      order: 'NEWEST',
      months,
      ...(language && { language }),
    });
    return promise
      .then(
        (postStatsByDate) => dispatch(fetchPostStatsByDateSuccess(get(postStatsByDate, 'stats', []))),
        () => dispatch(fetchPostStatsByDateFailure()),
      )
      .then(() => Promise.resolve());
  };
};
