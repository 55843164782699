import { SANTA_MEMBERS_APP_ID, MEMBERS_ABOUT_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createAction } from '@wix/communities-blog-client-common';
import { getProfileUrls } from './profile-urls-selectors';
import { getIsMemberAreaInstalled } from '../communities-context/communities-context-selectors';

export const SET_PROFILE_URLS = 'profileUrls/SET';

export const setProfileUrls = createAction(SET_PROFILE_URLS);

export const fetchProfileUrls = () => async (dispatch, getState, { wixCodeApi }) => {
  const state = getState();
  if (!getIsMemberAreaInstalled(state)) {
    return;
  }
  const fetchedProfileUrls = getProfileUrls(state);
  const siteMembersToFetch = Object.values(state.posts).reduce((acc, val) => {
    if (
      val.owner.privacyStatus === 'PUBLIC' &&
      !fetchedProfileUrls[val.owner.siteMemberId] &&
      !acc.includes(val.owner.siteMemberId)
    ) {
      acc.push(val.owner.siteMemberId);
    }
    return acc;
  }, []);

  if (siteMembersToFetch.length < 1) {
    return;
  }

  try {
    const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);
    const promises = siteMembersToFetch.map((siteMemberId) =>
      api.getSectionUrl({
        appDefinitionId: MEMBERS_ABOUT_APP_ID,
        sectionId: 'about',
        memberId: siteMemberId,
      }),
    );
    const urls = await Promise.all(promises);
    const urlsMap = urls.reduce((acc, val, index) => {
      acc[siteMembersToFetch[index]] = val;
      return acc;
    }, {});

    dispatch(setProfileUrls(urlsMap));
  } catch (error) {
    console.log(error);
  }
};
