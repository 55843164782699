import { SECTION_CATEGORY } from '@wix/communities-blog-client-common';
import { getEntityCount, getPaginationPageSize } from '../../selectors/pagination-selectors';
import { getLastPage } from '../pagination';

import { getSectionUrl } from '../../store/topology/topology-selectors';
import { getHashtagUrl } from '../get-hashtag-url';
import { getPaginationUrls } from './get-pagination-item-data';
import { createHashtagPageUrl } from '../navigation';

export const generateHashtagSEOTags = ({ state, hashtag, page = 1, posts }) => {
  const sectionUrl = getSectionUrl(state);

  const hashtagUrl = getHashtagUrl(sectionUrl, hashtag);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const pagination = {
    ...getPaginationUrls(hashtagUrl, page, lastPage, state),
    totalPages: parseInt(Math.ceil(entityCount / pageSize), 10),
    currentPage: page,
  };

  const itemData = {
    title: `#${hashtag}`,
    label: hashtag,
    url: createHashtagPageUrl(sectionUrl, hashtag, page),
    items: {
      numberOfItems: posts.length,
    },
    pagination,
  };

  return {
    itemType: 'BLOG_HASHTAG',
    itemData: {
      hashtag: itemData,
    },
  };
};
