import { isExperimentEnabled, urijs, createPerformanceTracker } from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR, EXPERIMENT_OOI_IN_EDITOR } from '@wix/communities-blog-experiments';
import { setRouterMatch } from '../router';
import { parseInstance } from '../store/instance-values/parse-instance';
import { addErrorState, addDebugState } from '../store/debug-state/debug-state-actions';
import createRequest from '../services/create-request';
import { validateLanguage } from '../services/validate-language';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import getEnvironment from '../services/get-environment';
import { isSite } from '../store/basic-params/basic-params-selectors';

const REGEX_POST_PAGE_PREVIEW = /[0-9a-fA-F]+\/preview\/([^/]+)\/?/;

export const getPreviewInstance = (location) => {
  const match = location.path.join('/').match(REGEX_POST_PAGE_PREVIEW);
  return match ? match[1] : undefined;
};

export const getInstance = (wixCodeApi) => () => wixCodeApi.site.getAppToken(BLOG_APP_ID);

export const resolveUser = (wixCodeApi, allowPreviewInstance) => {
  const user = wixCodeApi.user.currentUser;
  const isSeo = wixCodeApi.seo.isInSEO();
  const previewInstance = allowPreviewInstance && !isSeo && getPreviewInstance(wixCodeApi.location);
  if (previewInstance) {
    user.loggedIn = true;
    user.instance = previewInstance;
    user.id = parseInstance(previewInstance).uid;
  } else {
    user.instance = getInstance(wixCodeApi)();
  }
  return user;
};

const noop = () => {};

export const createLogger = (isDebug, isProduction) => (!isProduction || isDebug ? console.log : noop);

export const onLocationChange = (wixCodeApi, callback) => {
  if (!wixCodeApi.location.onChange) {
    return;
  }
  let currentPathname = wixCodeApi.location.path.join('/');
  wixCodeApi.location.onChange((args) => {
    const pathname = args.path.join('/');
    if (pathname === currentPathname) {
      return;
    }
    currentPathname = pathname;
    callback(args);
  });
};

export const doRouting = ({ router, pathname, store, isInitialLoad = true }) =>
  isInitialLoad
    ? router.resolve(pathname).then((match) => store.dispatch(setRouterMatch(match)))
    : router.match(pathname);

export function initializeNavigationHandlerForEditor({ store, router }) {
  if (typeof window !== 'undefined') {
    window.__navigateWithinBlogInternally = (pathname) => {
      doRouting({
        store,
        router,
        pathname,
        isInitialLoad: false,
      });
    };
  }
}

export const createRequestWithBaseUrl = ({ wixCodeApi, getStore, bundleName }) => (baseUrl) => {
  const { isDebug, isSSR } = getEnvironment(wixCodeApi);
  return createRequest({
    baseUrl,
    getInstance: getInstance(wixCodeApi),
    locale: validateLanguage(wixCodeApi.site.language),
    trackError: getStore ? (error) => getStore().dispatch(addErrorState(error)) : undefined,
    logResponse: isDebug && getStore ? (response) => getStore().dispatch(addDebugState(response)) : undefined,
    petriOvr: wixCodeApi.location.query.petri_ovr,
    siteRevision: wixCodeApi.site.revision,
    isDebug,
    performanceTracker: createPerformanceTracker(bundleName, { isDebug, isSSR }),
  });
};

export const appendOriginInBackend = ({ wixCodeApi, baseUrl }) => {
  const origin = urijs(wixCodeApi.location.baseUrl).origin();
  const isBackend = wixCodeApi.window.rendering.env === 'backend';
  return isBackend ? `${origin}${baseUrl}` : baseUrl;
};

export const createControllerId = () => new Date().getTime();

export const isFrameless = ({ store, wixCodeApi }) => {
  const state = store.getState();
  const { isPreview, isEditor } = getEnvironment(wixCodeApi);
  const isPreviewFrameless = isPreview && !isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR);
  const isEditorFrameless = isEditor && isExperimentEnabled(state, EXPERIMENT_OOI_IN_EDITOR);

  return isSite(state) || isPreviewFrameless || isEditorFrameless;
};
