import { values, includes } from 'lodash';
import { getTopology } from '../store/topology/topology-selectors';
import { INSTANCE_ID_WIX } from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { isSite } from '../store/basic-params/basic-params-selectors';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { SECTION_BLOG_POST_PAGE, isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';
import Wix from '../services/wix-sdk-polyfill';
import { getCustomPostUrl } from '../store/custom-routes/custom-routes-selectors';

export const navigateInPreview = (link) => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, sectionUrl } = getTopology(getState());
  const path = link.replace(baseUrl, '');
  if (path === '' || path === '/') {
    const sectionPath = sectionUrl.replace(baseUrl, '');
    return wixCodeApi.location.to(sectionPath);
  }
  return wixCodeApi.location.to(path);
};

export const navigateWithinBlog = (path) => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

export const navigateToCustomPostUrl = (customUrl, fallbackPath) => (dispatch, getState, { wixCodeApi }) => {
  if (customUrl) {
    const { baseUrl } = getTopology(getState());
    return wixCodeApi.location.to(customUrl.replace(baseUrl, ''));
  }

  return dispatch(navigateWithinPostPage(fallbackPath));
};

export const navigateWithinPostPage = (path) => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

export const navigateProGalleryWithinPostPage = (postSlug) => (dispatch, getState, { wixCodeApi, appParams }) => {
  const state = getState();
  const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(state);
  const sectionPath = sectionUrl.replace(baseUrl, '');

  const customPostUrl = getCustomPostUrl(state, postSlug).replace(baseUrl, '');
  const path = customPostUrl || `${sectionPath}/${postSlug}`;

  if (!isSite(state)) {
    if (isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR)) {
      return Wix.Utils.navigateToSection(
        {
          appDefinitionId: BLOG_APP_ID,
          sectionId: SECTION_BLOG_POST_PAGE,
          shouldRefreshIframe: false,
        },
        postSlug,
      );
    } else {
      wixCodeApi.location.to(path);
    }
  }

  // Temporary hack for marketing sites, because wix code navigation doesn't work properly
  if (includes(values(INSTANCE_ID_WIX), appParams.instanceId)) {
    return wixCodeApi.location.to(customPostUrl || `${sectionUrl}/${postSlug}`);
  }

  return wixCodeApi.location.to(path);
};
