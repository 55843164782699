import { get, debounce } from 'lodash';
import { createAction } from '@wix/communities-blog-client-common';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const FETCH_MENTIONS_REQUEST = 'mentions/FETCH_REQUEST';
export const FETCH_MENTIONS_SUCCESS = 'mentions/FETCH_SUCCESS';
export const FETCH_MENTIONS_FAILURE = 'mentions/FETCH_FAILURE';

export const fetchMentionsRequest = createAction(FETCH_MENTIONS_REQUEST);
export const fetchMentionsSuccess = createAction(FETCH_MENTIONS_SUCCESS);
export const fetchMentionsFailure = createAction(FETCH_MENTIONS_FAILURE);

export const fetchMentions = (query) => async (dispatch, _getState, { request }) => {
  dispatch(fetchMentionsRequest({ query }));
  const config = {
    apiBaseUrl: '/_api/members/v1',
  };
  const body = {
    fieldSet: 'PUBLIC',
    query: {
      filter: {
        'profile.nickname': { $contains: query },
        privacyStatus: 'PUBLIC',
      },
    },
    paging: {
      limit: 10,
      offset: 0,
    },
  };
  try {
    const response = await request.post(`/members/query`, body, config);
    const mentions = response.members.map((member) => ({
      name: member.profile.nickname,
      slug: member.profile.slug,
      avatar: get(member, 'profile.photo.url'),
    }));

    dispatch(fetchMentionsSuccess(mentions));
    return mentions;
  } catch (error) {
    dispatch(fetchMentionsFailure());
    return [];
  }
};

const createDebouncedFetchMentions = () => {
  const debouncedFetchMentions = debounce((dispatch, query, cb) => dispatch(fetchMentions(query)).then(cb), 500);
  return (query) => (dispatch) => new Promise((resolve) => debouncedFetchMentions(dispatch, query, resolve));
};

export const fetchMentionsPromisified = createPromisifiedAction(createDebouncedFetchMentions(), (res) => res);
