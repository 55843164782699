import { getWixDataCategoryId, getWixDataTagId, POST_LIST_SETTINGS_PARAMS } from '@wix/communities-blog-client-common';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { handleExperimentsResponse } from '../../common/store/experiments/experiments-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { handlePostListPostsResponse } from './fetch-post-list-posts';
import { getQueryLocale } from '../../common/selectors/locale-selectors';

export const fetchInitialData = ({ instanceId } = {}) => async (dispatch, getState, { aggregatorRequest }) => {
  const state = getState();
  const postLimit = getPostListWidgetPageSize(state);
  const featuredOnly = getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath });
  const categoryId = getWixDataCategoryId(state);
  const tagId = getWixDataTagId(state);
  const language = getQueryLocale(getState());

  const { experiments, translations, posts } = await aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      postLimit && `postLimit=${postLimit}`,
      featuredOnly && `featuredOnly=${featuredOnly}`,
      categoryId && `categoryId=${categoryId}`,
      tagId && `tagId=${tagId}`,
      language && `language=${language}`,
    ]
      .filter(Boolean)
      .join('&')}`,
      { throwOnInvalidJson: true }
  );

  await Promise.all([
    dispatch(handleExperimentsResponse(experiments)),
    dispatch(handleTranslationsResponse(translations)),
  ]);
  await dispatch(fetchTopology(instanceId));
  await dispatch(handlePostListPostsResponse(posts));
};
