import { get } from 'lodash';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import {
  createPageUrl,
  getAppData,
  getCategoryUrl,
  getImageUrl,
  getSchemaEnabled,
} from '@wix/communities-blog-client-common';
import { getPostsByCategoryIdAndPage } from '../../selectors/post-selectors';
import { getIsSitePropertiesLoaded } from '../../store/is-loaded/is-loaded-selectors';
import { fetchSiteProperties } from '../../store/site-properties/site-properties-actions';
import { getSiteProperties } from '../../store/site-properties/site-properties-selectors';
import { getSectionUrl, getPostPageSectionUrl } from '../../store/topology/topology-selectors';
import { getCategorySchema } from '../json-schema';
import { getPaginationItemData } from './get-pagination-item-data';
import { getCategoriesMap } from '../../selectors/categories-selectors';
import { getQueryLocale } from '../../selectors/locale-selectors';

const getTranslatedPagesData = (translations) =>
  translations.map((translation) => ({
    langCode: translation.language,
    url: `${translation.url.base}${translation.url.path}`,
  }));

export const generateCategorySEOTags = async ({ appConfig, sectionUrl, category, store, page, showPagination, t }) => {
  let state = store.getState();
  const isMultilingualEnabled = Boolean(getQueryLocale(state));

  const categoryUrl = getCategoryUrl(sectionUrl, appConfig.categoryPath, category.slug);
  const categoryUrlWithPage = createPageUrl(page, categoryUrl || getSectionUrl(state));

  const itemData = {
    title: appConfig.useCategoryMenuLabelForMetadataTitle ? category.menuLabel : category.label,
    label: category.menuLabel,
    description: category.description,
    canonicalUrl: categoryUrlWithPage,
  };

  if (appConfig.isWP || getSchemaEnabled(state)) {
    if (!getIsSitePropertiesLoaded(state)) {
      await store.dispatch(fetchSiteProperties());
    }

    state = store.getState();
    itemData.structuredData = JSON.stringify(
      getCategorySchema({
        posts: getPostsByCategoryIdAndPage(state, category._id, page),
        category,
        categoryMap: getCategoriesMap(state),
        appConfig,
        appData: getAppData(state),
        siteProperties: getSiteProperties(state),
        sectionUrl,
        postPageSectionUrl: getPostPageSectionUrl(state),
      }),
    );
  }

  const itemDataWithPagination = getPaginationItemData({
    itemData,
    state,
    url: categoryUrl,
    page,
    t,
    updateTitle: showPagination,
  });

  if (category.cover) {
    const { width, height } = category.cover;
    const image = {
      url: getImageUrl({ imageHost: appConfig.imageHost, image: category.cover }),
      width,
      height,
    };

    if (isMultilingualEnabled) {
      itemDataWithPagination.mainImage = {
        ...image,
        hasImage: true,
      };
    } else {
      itemDataWithPagination.image = image;
    }
  }

  if (isMultilingualEnabled) {
    itemDataWithPagination.language = category.language;
    itemDataWithPagination.items = {
      numberOfItems: category.postAmount,
    };
    itemDataWithPagination.translatedPages = get(category, 'translations.length')
      ? getTranslatedPagesData(category.translations)
      : [];
  }

  return {
    itemType: ITEM_TYPES.BLOG_CATEGORY,
    itemData: { category: itemDataWithPagination },
  };
};
